import React from 'react'
// import './pagination.scss'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import theme from '../../theme'
// prev(disabled) [1] 2 3 4 5 next
// prev(disabled) [1] 2 3 4 5 ... 10 next
// prev 1... 6 7 [8] 9 10 ... 20 next
// prev 1 ... 6 7 8 9 [10] next(disabled)

const useStyles = createUseStyles({
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        '& ul': {
            margin: [0, theme.spacing(2), 0, theme.spacing(2)],
            listStyle: 'none',
            padding: 0,
            '& > li': {
                display: 'inline-block',
            },
            '& > li:not(:last-child)': {
                paddingRight: theme.spacing(3),
            },
            '& span': {
                paddingRight: theme.spacing(3),
            },
        },
        '& a.disabled': {
            textDecoration: 'none',
        },
    },
})

const Ellipsis = () => <span className="ellipsis">...</span>
const LinkItem = ({ pageID }) => (
    <li>
        <Link to={`/blog/page/${pageID}`}>{pageID}</Link>
    </li>
)

const Pagination = (props) => {
    const { min, max, current, size } = props
    const classes = useStyles()

    const children = []
    children.push(<LinkItem pageID="1" />)
    if (current > size) {
        children.push(<Ellipsis />)
    }
    const start = parseInt(current - size / 2)
    const end = parseInt(current + size / 2)
    for (let i = start; i <= end; i++) {
        children.push(<LinkItem pageID={i} />)
    }
    if (current < max - size) {
        children.push(<Ellipsis />)
    }
    children.push(<LinkItem pageId={max} />)

    return (
        <div className={classes.pagination}>
            <Link
                to={`/blog/page/${current - 1}`}
                onClick={(e) => {
                    current - 1 < 1 && e.preventDefault()
                }}
                className={classNames({ disabled: current - 1 < 1 })}
            >
                Prev
            </Link>

            <ul>
                {current} / {max}
            </ul>

            <Link
                to={`/blog/page/${current + 1}`}
                onClick={(e) => {
                    current + 1 > max && e.preventDefault()
                }}
                className={classNames({ disabled: current + 1 > max })}
            >
                Next
            </Link>
        </div>
    )
}

Pagination.defaultProps = {
    size: 5,
}

export default Pagination

import React from 'react'
import { createUseStyles } from 'react-jss'
import BackToTopIcon from './backToTop.svg'
import theme from '../../theme'

const useStyles = createUseStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        right: `${theme.spacing(1)}vw`,
        bottom: `${theme.spacing(1)}vw`,
        zIndex: 999,
        '& > div': {
            background: '#3a3a3a',
            padding: '16px',
        },
        '& svg': {
            width: '32px',
            fill: '#DDD',
            display: 'block',
        },
    },
    [theme.breakpoints.sm]: {
        // TODO: 因为在sm上暂时找不到合适的方法去放置这个返回顶部的按钮，所以暂时在sm上去掉这个按钮
        root: {
            display: 'none',
        },
    },
})

const BackToTop = () => {
    return (
        <div onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            <BackToTopIcon />
        </div>
    )
}

const FloatingButton = (props) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <BackToTop />
        </div>
    )
}

export default FloatingButton

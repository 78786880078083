import React from 'react'
// import './article.scss';
// import './title.scss';
import classNames from 'classnames'
import { ImageViewer, showImage } from './imageViewer'
import { Link } from 'gatsby'
import './hljsTheme.css'
import { createUseStyles } from 'react-jss'
import theme from '../../theme'

const useStyles = createUseStyles({
    articleText: {
        '& h1': {
            marginTop: 0,
            fontSize: theme.fontSize(3),
        },
        '& .text': {
            fontSize: theme.fontSize(1),
            lineHeight: 1.7,
            '& h2, h3, h4, h5': {
                lineHeight: 1.5,
                border: '1px #555 solid',
                borderStyle: 'none none solid none',
                paddingBottom: theme.spacing(2),
                paddingTop: theme.spacing(2),
            },
            '& p:first-child': {
                marginTop: 0,
            },
            '& img': {
                display: 'block',
                maxWidth: '70%',
                margin: '0 auto',
                cursor: 'pointer',
            },
            '& p > code': {
                // XXX: hardcoded color value
                background: '#333',
                padding: '3px 5px',
                overflowWrap: 'anywhere',
            },
            '& pre > code': {
                padding: theme.spacing(2),
            },
            '& blockquote': {
                margin: `${theme.spacing(2)}px 0`,
                border: '5px #ddd solid',
                borderStyle: 'none none none solid',
                paddingLeft: theme.spacing(2),
            },
        },
    },

    title: {
        position: 'relative',
        paddingBottom: theme.spacing(3),
    },
    bg: {
        width: '100%',
        height: `${theme.spacing(2)}vw`,
        display: 'block',
        backgroundSize: 'cover',
        backgroundPosition: ['50%', '50%'],
    },
    info: {
        bottom: theme.spacing(3),
        left: 0,
        right: 0,
        '& h1': {
            margin: 0,
            '& a': {
                textDecoration: 'none',
            },
            padding: [theme.spacing(1), 0],
        },
        '&.bottom h1': {
            paddingBottom: 0,
        },
    },
    [theme.breakpoints.sm]: {
        bg: {
            height: `${theme.spacing(3)}vw`,
        },
        info: {
            '& h1': {
                fontSize: theme.fontSize(2),
            },
        },
        title: {
            paddingBottom: theme.spacing(2),
        },
    },
})

export const Title = (props) => {
    const classes = useStyles()

    const InfoCard = (p) => (
        <div
            className={classNames(classes.info, {
                top: p.pos, // pos == true when info card above img
                bottom: !p.pos,
            })}
        >
            <h1>
                <Link to={props.url}>{props.title}</Link>
            </h1>
        </div>
    )

    return (
        <div
            className={classNames(classes.title, {
                has_image: props.backgroundImage,
            })}
        >
            {props.infoCardPosition == 'top' && <InfoCard pos={true} />}
            {props.backgroundImage && (
                <div
                    className={classes.bg}
                    style={{
                        backgroundImage: `url("${props.backgroundImage}")`,
                    }}
                />
            )}
            {props.infoCardPosition != 'top' && <InfoCard pos={false} />}
            {props.summary && (
                <div className="summary">
                    {props.summary}
                    <Link to={props.url}>阅读更多</Link>
                </div>
            )}
        </div>
    )
}

Title.defaultProps = {
    title: '测试用的文章标题',
    infoCardPosition: 'bottom',
    url: '#',
    // backgroundImage: '',
    // summary: 'summary'
}

const ImgBox = (props) => {
    return <img src={props.src} onClick={() => showImage(props.src)} />
}

const Article = ({ html }) => {
    const classes = useStyles()

    return (
        <div className={classes.articleText}>
            <div className="text" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    )
}

export default Article

import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import CloseIcon from '../../assests/icons/close.svg'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import theme from '../../theme'
// import './imageViewer.scss';

const useStyles = createUseStyles({
    imageViewer: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        '&::before': {
            content: '',
            opacity: 0.7,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: '#000',
            zIndex: '-1',
        },
        '&.show': {
            display: 'flex',
        },
    },
    closeBtn: {
        position: 'absolute',
        top: theme.spacing(3),
        right: theme.spacing(3),
        fill: theme.palette.primary,
        height: '50px',
        width: '50px',
    },
})

export const ImageViewer = (props) => {
    const classes = useStyles()

    return (
        <div
            className={classNames(classes.ImageViewer, 'show')}
            onClick={props.onClose}
        >
            <CloseIcon className={classes.closeBtn} onClick={props.onClose} />
            <img src={props.imgSrc} onClick={(e) => e.stopPropagation()} />
        </div>
    )
}

export const showImage = (src) => {
    const holder = document.createElement('div')
    document.body.appendChild(holder)
    const dom = (
        <ImageViewer
            imgSrc={src}
            onClose={() => document.body.removeChild(holder)}
        />
    )
    ReactDOM.render(dom, holder)
}

import React from 'react'
// import { Framework } from './framework';
// import './grid.scss';
// import './framework.scss';
import { Header } from '../header/header'
import { Footer } from '../footer/footer'
// import floatingButton from '../floatingButton';
import FloatingButton from '../floatingButton'
import { createUseStyles } from 'react-jss'
import theme from '../../theme'
import { Helmet } from 'react-helmet'

const useStyles = createUseStyles({
    root: {
        width: '60%',
        margin: '0 auto',
    },
    container: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },

    [theme.breakpoints.sm]: {
        root: {
            width: '100%',
        },
        container: {
            padding: theme.spacing(1),
        },
    },
})

export const Layout = (props) => {
    const classes = useStyles()
    return (
        <div className={`App ${classes.root}`}>
            {/* Google Analytics */}
            <Helmet>
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=G-7VQN80N02S"
                ></script>

                <script>
                    {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                    
                      gtag('config', 'G-7VQN80N02S');
                      `}
                </script>
            </Helmet>
            <FloatingButton />
            <Header />
            <div className={classes.container}>{props.children}</div>
            <Footer />
        </div>
    )
}

export const withLayout = (Children) => (props) =>
    <Layout>{<Children {...props} />}</Layout>

// TwoGrid.defaultProps = {
//     leftChildren: <span>left</span>,
//     rightChildren: <span>right</span>
// }

import React from 'react'
// import './header.scss';
import { Link } from 'gatsby'
import { createUseStyles } from 'react-jss'
import theme from '../../theme'

const tbd = () => alert('tbd')
const useStyles = createUseStyles({
    header: {
        padding: [theme.spacing(2), theme.spacing(3)],
        fontSize: theme.spacing(2),
        '&.site_title': {
            color: theme.palette.primary,
        },
        '& a': {
            textDecoration: 'none',
        },
        '& a:not(:first-child)': {
            marginLeft: theme.spacing(1),
        },
    },
    [theme.breakpoints.sm]: {
        header: {
            padding: [theme.spacing(2), theme.spacing(1)],
        },
    },
})

export const Header = (props) => {
    const classes = useStyles()
    return (
        <header className={classes.header}>
            {/* <a className="" href="#">HOME</a>
             */}
            <Link to="/blog" className="site_title">
                BLOG
            </Link>
            {/* <a className="site_title" href="#">{props.SiteName}</a> */}
            {/* <a className="" href="#" onClick={tbd}>TOPIC</a> */}
            <a className="" href="#" onClick={tbd}>
                TAG
            </a>
            <Link to="/">HOME</Link>
        </header>
    )
}

Header.defaultProps = {
    SiteName: 'LIGHTHOUSE',
}

import React, { useEffect } from 'react'
import { withLayout } from '../../components/layout/layout'
import { graphql } from 'gatsby'
import Article, { Title } from '../../components/article/article'
import Pagination from '../../components/pagination'
import { Helmet } from 'react-helmet'

const generateSummary = (text) => {
    // remove html tag
    let textWithoutHTML = text.replace(/<[^>]*>?/gm, '')
    let parts = textWithoutHTML.split('[[MORE]]')
    if (parts.length > 1) {
        return parts[0]
    } else {
        // TODO
        return textWithoutHTML.slice(0, 100) + '...'
    }
}

const ArticleList = ({ pageContext }) => {
    // console.log("每个人都会有一些信仰，有的人信仰努力就会换来成果\n" +
    //             "有的人信仰真挚的投入就会换来纯真的感情\n" +
    //             "也有的人，希望凭借自己坚定的的信念，能够像那只扇动翅膀的蝴蝶一样，最终能够改变整个环境\n" +
    //             "\n那么，旅行者，能否告诉我，你的信仰是什么？")

    const data = pageContext.data

    useEffect(() => {
        document.title = `文章列表 - Lighthouse: techmoe的技术博客`
        if (data.allMarkdownRemark.pageInfo.currentPage != 1) {
            document.title = `文章列表 - 第${data.allMarkdownRemark.pageInfo.currentPage}页 - Lighthouse: techmoe的技术博客`
        }
    }, [])

    const cards = []
    for (let d of data.allMarkdownRemark.edges) {
        cards.push(
            <Title
                title={d.node.frontmatter.title || '<no title>'}
                backgroundImage={
                    d.node.frontmatter.featured_image ||
                    `https://picsum.photos/seed/${d.node.frontmatter.slug}/500/350`
                }
                infoCardPosition={'top'}
                summary={generateSummary(d.node.html)}
                url={`/blog/${d.node.frontmatter.slug}`}
            />
        )
    }
    return (
        <div className="article_list">
            {/* SEO: noindex */}
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>

            {cards}
            <Pagination
                {...{
                    min: 1,
                    max: data.allMarkdownRemark.pageInfo.pageCount,
                    current: data.allMarkdownRemark.pageInfo.currentPage,
                }}
            />
        </div>
    )
}

const ArticleListTemplate = withLayout(ArticleList)

export default ArticleListTemplate

import React from 'react'
import { createUseStyles } from 'react-jss'
// import './footer.scss';
import theme from '../../theme'

const useStyles = createUseStyles({
    root: {
        textAlign: 'center',
        paddingBottom: theme.spacing(3),
    },
})

export const Footer = (props) => {
    const classes = useStyles()
    return (
        <footer className={classes.root}>
            <p>© techmoe</p>
            <p>Proudly powered by reactjs</p>
        </footer>
    )
}
